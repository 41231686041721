// @ts-ignore
import React from 'react';

import Layout from '../components/Layout';
import {graphql} from "gatsby";
import { IGatsbyImageData } from 'gatsby-plugin-image'

import Gallery from '@browniebroke/gatsby-image-gallery'

interface ImageSharpEdge {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData
      full: IGatsbyImageData
    }
  }
}

interface PageProps {
  data: {
    images: {
      edges: ImageSharpEdge[]
    }
  }
}

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const images = data.images.edges.map(({node}, index) => ({
    ...node.childImageSharp,
  }));
  return (
      <Layout>
        <div id="main">
          <div className="box container">
            <header>
              <h2>Laadpalen</h2>
            </header>
            <section>
              <header>
                <p>
                  Meer en meer mensen stappen over naar elektrisch rijden. Hiervoor is het noodzakelijk om thuis een laadsysteem te installeren. Wij bekijken samen met u wat er nodig is om uw elektrische installatie hieraan aan te passen en welke laadoplossing het best bij u past.
                </p>
              </header>
            <Gallery images={images} />
            </section>

          </div>
        </div>
      </Layout>
  )
}

export const pageQuery = graphql`
  query ImagesForChargingPolesGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "laadpalen" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default IndexPage;
